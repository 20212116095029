import { ServerResponse } from '../../../../src/types/app';
import { CurrencySchema, LanguageSchema, HubSchema } from '../../../../src/types/schema';
import { api } from '.';

const appApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query<CurrencySchema[], void>({
      keepUnusedDataFor: 60 * 60 * 24,
      query: () => 'currencies',
      transformResponse: (response: ServerResponse<CurrencySchema[]>) => response.data,
    }),
    getLanguages: builder.query<LanguageSchema[], void>({
      query: () => 'dashboard/languages',
      transformResponse: (response: ServerResponse<LanguageSchema[]>) => response.data,
    }),
    getHubs: builder.query<HubSchema[], void>({
      query: () => 'hubs',
      transformResponse: (response: ServerResponse<HubSchema[]>) => response.data,
    }),
  }),
});

export const {
  useGetCurrenciesQuery,
  useGetLanguagesQuery,
  useGetHubsQuery,
} = appApi;
