import {
  Box,
  Grid, Typography,
} from '@mui/material';
import { useGetHubsQuery } from '../../plugins/gatsby-plugin-redux/store/api/app.api';
import Container from '../components/app/Container';
import SEO from '../components/app/SEO';
import ConnectedWorld from '../components/icons/ConnectedWorld';

const Hubs = () => {
  const { data: hubs } = useGetHubsQuery();

  return (
    <Container sx={{ paddingY: 5 }}>
      <SEO
        title="Publish once, Distribute Across Africa"
        description="Send your books to our partners anywhere in the world
            while we handle shipments and fulfilment."
      />
      <Grid container alignItems="center" justifyContent="space-between" columnSpacing={3} rowGap={3}>
        <Grid item xs={12} md={5}>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            fontWeight="600"
          >
            Publish once, Distribute Across Africa
          </Typography>
          <Typography gutterBottom>
            Litireso is the first platform to integrate with businesses to support
            them while ensuring books are globally available and accessible.
          </Typography>
          <Typography>
            Send your books to our partners anywhere in the world
            while we handle shipments and fulfilment.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} sm={7}>
          <ConnectedWorld sx={{ width: '100%' }} />
        </Grid>
      </Grid>

      <Typography
        variant="h3"
        component="h2"
        textAlign="center"
        gutterBottom
        fontWeight="600"
        marginTop={5}
        marginBottom={3}
        color="primary"
      >
        Directory
      </Typography>

      <Grid container rowSpacing={5}>
        {hubs?.map((hub) => (
          <Grid item md={6} xs={12}>
            <Box>
              <Typography
                variant="h4"
                component="h1"
                fontWeight="600"
              >
                {hub.name}
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                fontWeight="600"
                marginTop={2}
              >
                Opening hours
              </Typography>

              <Box>
                {hub.schedule.map((schedule) => (
                  <Box>
                    <Typography component="span">{`${schedule.dayOfWeek}:`}</Typography>
                    {' '}
                    <Typography component="time">
                      {schedule.opens}
                    </Typography>
                    {' - '}
                    <Typography component="time">
                      {schedule.closes}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Typography
                variant="h5"
                component="h2"
                marginTop={2}
                gutterBottom
                fontWeight="600"
              >
                Mailing address
              </Typography>
              <Box>
                <Typography>{hub.address1}</Typography>
                {hub.address2 && (
                <Typography>
                  {hub.address2}
                </Typography>
                )}
                <Typography>{hub.city}</Typography>
                <Typography>{hub.state}</Typography>
                <Typography>{hub.country}</Typography>
                <Typography>{hub.postalCode}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
export default Hubs;
